import React from "react";

const SectionTitle = ({ title }) => {
  return (
    <div className="header-section  mt-10 mb-5 border-black flex justify-between items-center my-10">
      <h2 className="title text-3xl">{title}</h2>
    </div>
  );
};

export default SectionTitle;
