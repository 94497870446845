import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { SlArrowRightCircle } from "react-icons/sl";

import {
  addToSingleCart,
  addtoCart,
  decreaseCart,
  getSubtotal,
  removeAllFromCart,
  removeFromCart,
  // removeFromCart,
} from "../features/products/cartSlice";

import { useNavigate } from "react-router-dom";
import { RiDeleteBin6Line } from "react-icons/ri";

const Checkout = () => {
  const navigate = useNavigate();
  const { cartItems: data, cartTotalAmount } = useSelector(
    (state) => state.cart
  );

  const session = useSelector((state) => state.user.userAndToken);
  console.log(session);
  // const [orderItem, setOrderItem] = useState(data);

  const [coupon, setCoupon] = useState(0);

  // console.log(JSON.stringify(orderItem).split(" "));
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(getSubtotal());
  }, [data, dispatch]);
  // const navigate = useNavigate();
  const [formData, setFormData] = useState({
    // name: "",
    // email: "",
    // phone: "",
    // address: "",
    shipping_cost: "",
    shipping_type: "Cash On Delivery",
    name: session?.user?.name,
    email: session?.user?.email,
    phone: session?.user?.phone,
    address: session?.user?.address,
  });
  const [couponCode, setCouponCode] = useState("");

  const handleCouponCodeChange = (event) => {
    setCouponCode(event.target.value);
  };

  const handleDecrease = (product) => {
    if (product?.cartQuantity > 1) {
      dispatch(decreaseCart(product));
    }
  };

  const handleIncrease = (product) => {
    dispatch(addtoCart(product));
    // product.cartQuantity++;
  };

  const handleRemove = (product) => {
    dispatch(removeFromCart(product));
  };

  const handleDlivarry = (e) => {
    setFormData({
      ...formData,
      shipping_cost: e.target.value,
    });
  };

  const handleGoToHome = () => {
    navigate("/");
  };
  const applyCoupon = async () => {
    setCouponCode("");
    try {
      await fetch(`${process.env.REACT_APP_URL}/api-coupon-code`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          coupon: couponCode,
        }),
      })
        .then((response) => response.json())
        .then((data) => {
          setCoupon(data);
        });
    } catch (err) {
      console.log(err.message);
    }
  };
  let grand_total = coupon.discount
    ? cartTotalAmount + +formData.shipping_cost + -coupon.discount
    : cartTotalAmount + +formData.shipping_cost + 0;
  const handleFormSubmit = async (e) => {
    e.preventDefault();

    if (formData.shipping_type === "Cash On Delivery") {
      try {
        const res = await fetch(`${process.env.REACT_APP_URL}/place-order`, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            name: formData.name,
            email: formData.email,
            phone: formData.phone,
            address: formData.address,
            item: data,
            shipping_cost: formData.shipping_cost,
            shipping_type: formData.shipping_type,
            grand_total,
          }),
        });

        if (!res.ok) throw new Error("Something Went Error");
        if (res.ok) {
          dispatch(removeAllFromCart());
          navigate("/successpage");
        }
      } catch (err) {
        console.log(err.message);
      }
    }

    if (formData.shipping_type === "SSL Commerz") {
      const queryParams = new URLSearchParams({
        name: formData.name,
        item: JSON.stringify(data),
        email: formData.email,
        phone: formData.phone,
        address: formData.address,
        grand_total,
        shipping_type: formData.shipping_type,
        shipping_cost: formData.shipping_cost,
      });

      window.location.href = `${process.env.REACT_APP_PAYMENT_URL}/?${queryParams}`;
    }
  };

  return (
    <form onSubmit={handleFormSubmit}>
      {data?.length > 0 ? (
        <>
          <div className="checkout-page mt-10 mb-10 container mx-auto grid grid-cols-1 lg:grid-cols-2 gap-10 lg:gap-20 ">
            <div className="billing-address border border-black bg-white">
              <div className="billing-address flex flex-col gap-2 order-2 md:order-1 p-5">
                <div className="form-control flex flex-col gap-2 mt-2">
                  <label className="">আপনার নাম</label>
                  <input
                    required
                    type="text"
                    onChange={(e) =>
                      setFormData({ ...formData, name: e.target.value })
                    }
                    value={formData.name}
                    className="px-3 py-2  rounded-md outline-none border focus:border-[#96680e] duration-300"
                    placeholder="আপনার নাম লিখুন"
                  />
                </div>
                <div className="form-control flex flex-col gap-2 mt-2">
                  <label className="">Your Email</label>
                  <input
                    required
                    type="email"
                    // onChange={(e) =>
                    //   setFormData({ ...formData, email: e.target.value })
                    // }
                    value={formData.email}
                    className="px-3 py-2  rounded-md outline-none border focus:border-[#96680e] duration-300"
                    placeholder="আপনার নাম লিখুন"
                  />
                </div>
                <div className="form-control flex flex-col my-2">
                  <label className="">আপনার মোবাইল নাম্বার</label>
                  <input
                    required
                    type="tel"
                    pattern="[0-9]{11}"
                    onChange={(e) =>
                      setFormData({ ...formData, phone: e.target.value })
                    }
                    value={formData.phone}
                    className="px-3 py-2 w-full rounded-md outline-none border focus:border-[#96680e] duration-300"
                    placeholder="আপনার নাম্বার লিখুন"
                  />
                </div>

                <div className="form-control flex flex-col  mb-3 ">
                  <label className="mb-2">আপনার ঠিকানা</label>
                  <textarea
                    required
                    type="text"
                    onChange={(e) =>
                      setFormData({ ...formData, address: e.target.value })
                    }
                    value={formData.address}
                    className="px-3 py-2 w-full rounded-md outline-none border focus:border-[#96680e] duration-300 resize-none"
                    placeholder="আপনার ঠিকানা লিখুন"
                  />
                </div>

                <div
                  className=" flex gap-3 items-center justify-center my-3"
                  onChange={(e) => handleDlivarry(e)}
                  required
                >
                  <label className="flex gap-3">
                    <input
                      required
                      type="radio"
                      value="80"
                      name="shipping_cost"
                      className="peer sr-only"
                    />
                    <div className="px-2 py-2 cursor-pointer rounded-md bg-white  text-gray-600 ring-1  transition-all hover:shadow  peer-checked:ring-red-600 peer-checked:ring-offset-2 peer-checked:bg-red-600 peer-checked:text-white">
                      <div className="flex flex-col items-center justify-center">
                        <span>Inside Dhaka</span>
                        <span>80</span>
                      </div>
                    </div>
                  </label>
                  <label className="flex gap-3">
                    <input
                      required
                      type="radio"
                      value="150"
                      name="shipping_cost"
                      className="peer sr-only"
                    />
                    <div className="px-2 py-2 cursor-pointer rounded-md bg-white p-1 text-gray-600 ring-1  transition-all hover:shadow peer-checked:ring-red-600 peer-checked:ring-offset-2 peer-checked:bg-red-600 peer-checked:text-white">
                      <div className="flex flex-col items-center justify-center">
                        <span>Outside Dhaka</span>
                        <span>150</span>
                      </div>
                    </div>
                  </label>
                </div>
              </div>
            </div>

            <div className="order-details  md:order-2 order-1 border border-black bg-white p-5">
              <div className="flex justify-center mb-5 underline underline-offset-8 text-red-400">
                <h2 className="text-2xl">আপনার অর্ডার</h2>
              </div>

              <div className="h-auto overflow-y-scroll order-details-container">
                {data?.map((product) => (
                  <div
                    key={product.id}
                    className="cart-items  h-20  flex  items-start gap-4 mt-3 overflow-hidden"
                  >
                    <div className="cart-img aspect-square w-20 flex justify-center items-center overflow-hidden ">
                      <img
                        src={`${process.env.REACT_APP_URL}/uploads/product/${product?.photos}`}
                        className="w-full object-cover "
                        alt=""
                      />
                    </div>
                    <div className="cart-description w-full overflow-hidden ">
                      <div className="flex flex-col">
                        <p className="truncate">
                          {product?.name?.substring(0, 60)} /
                          <span>{product?.sizeAtr}</span>
                        </p>
                        <div className="flex gap-5 items-center my-3">
                          <div className="cart-price">
                            {product?.response * product?.cartQuantity} ৳
                          </div>
                          <div>
                            <div className="">
                              <div className="counter flex">
                                <button
                                  onClick={() => handleDecrease(product)}
                                  className="h-10 w-10 bg-gray-100  border border-gray-300 active:bg-gray-700 active:text-gray-50"
                                >
                                  -
                                </button>
                                <span className="h-10 w-10 bg-gray-100 flex justify-center items-center border border-gray-300">
                                  {product?.cartQuantity}
                                </span>
                                <button
                                  onClick={() => handleIncrease(product)}
                                  className="h-10 w-10 bg-gray-100 border border-gray-300 active:bg-gray-700 active:text-gray-50"
                                >
                                  +
                                </button>

                                <div className="flex items-center justify-center px-5">
                                  <button
                                    className="text-base hover:text-red-500 duration-500 justify-center"
                                    onClick={() => handleRemove(product)}
                                  >
                                    <RiDeleteBin6Line />
                                  </button>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                ))}
              </div>
              <div className="shipping_Type  flex  md:flex-col lg:flex-row gap-5 "></div>
              <div className="payment-summary flex flex-col gap-3 mt-5 ">
                <div className="sub-total flex justify-between">
                  <h4 className="text-lg text-slate-400 font-semibold">
                    Sub Total
                  </h4>
                  <span className="text-slate-700 ">{cartTotalAmount} ৳</span>
                </div>
                <div className="shipping flex justify-between">
                  <h4 className="text-lg text-slate-400 font-semibold ">
                    Shipping
                  </h4>
                  <span className="text-slate-700 ">
                    {+formData.shipping_cost} ৳
                  </span>
                </div>
                <div className="shipping flex justify-between">
                  <h4 className="text-lg text-slate-400 font-semibold ">
                    Discount
                  </h4>
                  <span className="text-slate-700 ">
                    {coupon.discount ? +coupon.discount : 0} ৳
                  </span>
                </div>
                <div className="bg-black h-1"></div>
                <div className="sub-total flex justify-between">
                  <h4 className="text-lg text-slate-400 font-semibold">
                    Total
                  </h4>
                  <span className="text-slate-700 font-semibold ">
                    {grand_total} ৳
                  </span>
                </div>
              </div>

              <div className="form-control flex items-center justify-center">
                <button
                  type="submit"
                  className="bg-[#C3161C] px-5 py-4 rounded-md text-2xl text-white hover:bg-transparent hover:text-slate-700 border hover:border-red-500 hover:text-black duration-300"
                >
                  অর্ডার কনফার্ম করুন
                </button>
              </div>
            </div>
          </div>
        </>
      ) : (
        <div className="flex flex-col items-center justify-center">
          <div className="w-full h-40 mt-10 flex items-center justify-center">
            <span className="text-4xl">No Product for checkout !!!</span>
          </div>
          <div>
            <button
              onClick={handleGoToHome}
              className={` p-4 w-full rounded-md bg-red-600 hover:bg-[#3A3A3A]  text-white duration-300 flex items-center justify-center gap-5`}
            >
              continue shopping
              <span>
                <SlArrowRightCircle />
              </span>
            </button>
          </div>
        </div>
      )}
    </form>
  );
};

export default Checkout;
