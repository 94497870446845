import { currencyFormatter } from "../../utils/currencyFormatter";
import { useDispatch } from "react-redux";
import { AiOutlineHeart } from "react-icons/ai";
import {
  addToSingleCart,
  addtoCart,
  removeAllFromCart,
} from "../../features/products/cartSlice";
import { Link, useNavigate } from "react-router-dom";
import { useState } from "react";

const Card = ({ product }) => {
  console.log(product);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const addToCartHandler = (product) => {
    dispatch(addtoCart(product));
    navigate("/checkout");
  };

  const [cartQuantity, setCartQuantity] = useState(1);
  const [modal, setModal] = useState(false);

  const handleBuyNow = () => {
    dispatch(removeAllFromCart());
    dispatch(addToSingleCart({ ...product, cartQuantity }));
    navigate("/checkout");
  };

  const handleClose = () => {
    setModal(!modal);
  };

  const handleModalOpen = () => {
    setModal(!modal);
  };

  return (
    <Link
      to={`/productdetails/${product?.id}`}
      className="flex flex-col w-[180px]  bg-white border-2 group-hover:border-[#C3161C] rounded-xl duration-300"
    >
      <div className="image-section p-2 flex items-center justify-center">
        <div className="h-[9rem] w-[9rem] overflow-hidden">
          <img
            src={`${process.env.REACT_APP_URL}/uploads/product/${product?.photos}`}
            alt=""
            className="w-full h-full object-fill"
          />
        </div>
      </div>

      <div className="product-details  px-2">
        <div className=" hover:text-[#C3161C] truncate duration-300">
          {product?.name}
        </div>
        <div className="">
          <div className="flex items-center justify-between gap-5">
            <div className="flex items-center">
              <span className="">৳</span>
              <span> {product?.unit_price}</span>
            </div>
            <div className="bg-[#C3161C] py-1 px-2 flex gap-1 text-white">
              <div>
                {Math.round(
                  ((product?.unit_price - product?.after_discount) * 100) /
                    product?.unit_price
                )}
                <span>%</span>
              </div>
              <span>off</span>
            </div>
          </div>
          <div className="flex justify-center items-center gap-2">
            <span className="">৳</span>
            <span>{product?.after_discount}</span>
          </div>
        </div>
      </div>
      <button
        // to={`/productdetails/${product?.id}`}
        onClick={handleModalOpen}
        className="flex py-2 items-center justify-center  font-bold border border-[#10847E] bg-transparent hover:bg-[#10847E] text-[#10847E] hover:text-white duration-300"
      >
        অর্ডার করুন
      </button>
    </Link>
  );
};

export default Card;
