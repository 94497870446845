import React, { useCallback, useEffect, useRef, useState } from "react";

import { login, logout, update } from "../../features/auth/authSlice";
import { Link, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { RxDashboard } from "react-icons/rx";
import { FaSellsy } from "react-icons/fa";
import { FaUserEdit } from "react-icons/fa";
import axios from "axios";
import { PhoneInput } from "react-international-phone";
import "react-international-phone/style.css";

const UserDetails = () => {
  const session = useSelector((state) => state?.user?.userAndToken);

  const inputRef = useRef(null);

  const [loading, setLoading] = useState(false);

  const [userData, setUserData] = useState({
    id: session?.user?.id,
    name: session?.user?.name,
    email: session?.user?.email,
    phone: session?.user?.phone,
    address: session?.user?.address,
    country: session?.user?.country,
  });

  const dispatch = useDispatch();

  function openFileExplorer() {
    inputRef.current.value = "";
    inputRef.current.click();
  }

  const [picture, setPicture] = useState(null);

  const handleImage = (e) => {
    setPicture({ image: e.target.files[0] });
  };

  const handleUpdate = useCallback(
    async (e) => {
      e.preventDefault();

      setLoading(true);

      const formData = new FormData();
      formData.append("id", userData.id);
      formData.append("avatar", picture?.image);
      formData.append("name", userData.name);
      formData.append("email", userData.email);
      formData.append("phone", userData.phone);
      formData.append("address", userData.address);
      formData.append("country", userData.country);

      try {
        const res = await axios.post(
          `${process.env.REACT_APP_URL}/api/UserUpdate`,
          formData,
          {
            headers: {
              "Content-Type": "multipart/form-data",
            },
          }
        );
        if (res.data.status === 200) {
          // console.log(res.data.userinfo);
          // dispatch(logout());
          dispatch(update(res.data.userinfo));
          setLoading(false);
          // navigate("/login");
        }
      } catch (error) {
        console.log(error.message);
      }
    },
    [
      userData?.id,
      picture?.image,
      userData?.email,
      userData?.phone,
      userData?.address,
      userData?.name,
      userData?.country,
      dispatch,
    ]
  );

  return (
    <div className="container mx-auto">
      <div className="bg-white rounded-md overflow-hidden p-5">
        <div>
          <form onSubmit={handleUpdate} enctype="multipart/form-data">
            {/* user name */}
            <div className="form-control flex flex-col gap-2 mt-2">
              <label className="">Your Name</label>
              <input
                type="text"
                onChange={(e) =>
                  setUserData({ ...userData, name: e.target.value })
                }
                value={userData.name}
                className="px-3 py-2  rounded-md outline-none border focus:border-[#96680e] duration-300"
              />
            </div>
            {/* user email */}
            <div className="form-control flex flex-col gap-2 mt-2">
              <label className="">Your Email</label>
              <input
                type="text"
                // onChange={(e) =>
                //   setFormData({ ...formData, email: e.target.value })
                // }
                value={userData.email}
                className="px-3 py-2  rounded-md outline-none border focus:border-[#96680e] duration-300"
                placeholder="আপনার নাম লিখুন"
              />
            </div>
            {/* user address */}
            <div className="form-control flex flex-col gap-2 mt-2">
              <label className="">Your Address</label>
              <input
                type="text"
                onChange={(e) =>
                  setUserData({ ...userData, address: e.target.value })
                }
                value={userData.address}
                className="px-3 py-2  rounded-md outline-none border focus:border-[#96680e] duration-300"
              />
            </div>
            {/* user phone */}
            <div className="form-control flex flex-col gap-2 mt-2">
              <label className="">Your Number</label>
              <input
                type="number"
                onChange={(e) =>
                  setUserData({ ...userData, phone: e.target.value })
                }
                value={userData?.phone}
                className="px-3 py-2  rounded-md outline-none border focus:border-[#96680e] duration-300"
              />
            </div>
            {/* <PhoneInput
              placeholder="Enter phone number"
              value={result}
              onChange={(phone) => setPhone(phone)}
            /> */}

            {/* your country */}
            <div className="form-control flex flex-col gap-2 mt-2">
              <label className="">Your country</label>
              <input
                type="text"
                onChange={(e) =>
                  setUserData({ ...userData, country: e.target.value })
                }
                value={userData.country}
                className="px-3 py-2  rounded-md outline-none border focus:border-[#96680e] duration-300"
              />
            </div>
            {/* user images */}
            <div className="form-control flex flex-col gap-2 mt-2">
              <label className="">Your Image</label>
              <input
                type="file"
                accept="image/*"
                onChange={handleImage}
                ref={inputRef}
                name="image"
                className="hidden"
              />
              <label
                className={`h-20 text-xl font-semibold w-full flex items-center justify-center border border-dashed duration-300	 ${
                  !picture?.image?.name
                    ? "  text-red-600 border-red-600"
                    : "border-[#999] text-black"
                }  hover:border-[#F4891F] hover:text-[#F4891F]`}
                onClick={openFileExplorer}
                htmlFor="file"
              >
                {picture && picture?.image?.name}

                {!picture && "Upload Photo"}
              </label>
            </div>
            <div className="flex items-center justify-center my-10">
              {!loading ? (
                <button
                  type="submit"
                  className="bg-red-600 hover:bg-[#3A3A3A] py-3 px-8  rounded-md  text-white duration-300"
                >
                  Update
                </button>
              ) : (
                <button
                  disabled
                  className="bg-red-600  py-3 px-8  rounded-md  text-white duration-300"
                >
                  Updating your data ....
                </button>
              )}
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

export default UserDetails;
