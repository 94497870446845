import React, { useEffect, useState } from "react";

import { useDispatch, useSelector } from "react-redux";

import { IoEyeOutline } from "react-icons/io5";

import moment from "moment";
import { Link } from "react-router-dom";
import OrderDetails from "./OrderDetails";
import UserOrderCard from "../../components/dashboard/UserOrderCard";

import ResponsivePagination from "react-responsive-pagination";
import "react-responsive-pagination/themes/classic.css";

const UserHistory = () => {
  const session = useSelector((state) => state.user.userAndToken);

  const [invoice, setInvoice] = useState([]);
  console.log(invoice);
  const [modal, setModal] = useState(false);

  const handleOpen = () => {
    setModal(!modal);
  };

  const handleClose = () => {
    setModal(!modal);
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        const res = await fetch(
          `${process.env.REACT_APP_URL}/api/customer-invoice/${session?.user?.email}`
        );
        const data = await res.json();
        setInvoice(data);
      } catch (error) {
        console.log(error.message);
      }
    };
    fetchData();
  }, [session?.user?.email]);

  const totalPages = 120;
  const [currentPage, setCurrentPage] = useState(1);

  function handlePageChange(page) {
    setCurrentPage(page);
    // ... do something with `page`
  }
  return (
    <div className="container mx-auto">
      {/* <ResponsivePagination
        total={totalPages}
        current={currentPage}
        onPageChange={(page) => handlePageChange(page)}
      /> */}
      <div className="bg-white rounded-md overflow-hidden p-5">
        <div className="grid grid-cols-6">
          <span>code</span>
          <span>Date</span>
          <span>Amount</span>
          <span>delivery status</span>
          <span>payment status</span>
          <span>Options</span>
        </div>
        {invoice?.map((invoice) => (
          <UserOrderCard
            key={invoice.id}
            invoice={invoice}
            handleOpen={handleOpen}
            modal={modal}
            setModal={setModal}
            handleClose={handleClose}
          />
        ))}
      </div>
    </div>
  );
};

export default UserHistory;
