import React, { useCallback, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
// import { signUpUser } from "../features/auth/authSlice";
import { Link, useNavigate } from "react-router-dom";
import { axiosPost } from "../lib/axiosPost";
import toast from "react-hot-toast";
import { login } from "../features/auth/authSlice";

const SignUp = () => {
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    password: "",
  });

  const { items: logo } = useSelector((state) => state.logo);

  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState("");

  const dispatch = useDispatch();
  const navigate = useNavigate();
  // api/user/register

  const submitForm = useCallback(
    async (e) => {
      e.preventDefault();
      setIsLoading(true);
      const data = await axiosPost("/api/user/register", formData);

      if (data.status === 404) {
        setError(data.error);
      } else {
        if (data) {
          setIsLoading(false);
          setFormData({
            name: "",
            email: "",
            password: "",
          });
          dispatch(login(data));
          toast.success("Register successfull.");

          navigate("/login");
        } else {
          setIsLoading(false);
        }
      }
    },
    [formData, navigate, dispatch]
  );

  return (
    <section className="container mx-auto  h-full bg-neutral-200 dark:bg-neutral-700 ">
      <div className="container h-full p-10">
        <div className="g-6 flex h-full flex-wrap items-center justify-center text-neutral-800 dark:text-neutral-200">
          <div className="w-full">
            <div className="block rounded-lg bg-white shadow-lg dark:bg-neutral-800">
              <div className="g-0 lg:flex lg:flex-wrap">
                {/* <!-- Left column container--> */}
                <div className="px-4 md:px-0 lg:w-6/12">
                  <div className="md:mx-6 md:p-12">
                    {/* <!--Logo--> */}
                    <div className="text-center flex items-center justify-center bg-black text-white">
                      <img
                        src={`${process.env.REACT_APP_URL}/backend/assets/jpg/${logo?.avatar}`}
                        className="w-[8rem] mx-auto"
                        alt="logo"
                      />
                      <h4 className="mb-12 mt-1 pb-1 text-xl font-semibold">
                        {" "}
                      </h4>
                    </div>

                    <form onSubmit={submitForm}>
                      <p className="my-4">Please Register to your account</p>
                      {/* <!--Username input--> */}

                      <input
                        type="text"
                        id="name"
                        autofocus
                        onChange={(e) =>
                          setFormData({ ...formData, name: e.target.value })
                        }
                        placeholder="Enter Your Name"
                        className="px-4 py-2 transition duration-300 border border-gray-300 rounded focus:border-transparent focus:outline-none focus:ring-4 focus:ring-blue-200 w-full mb-4"
                      />
                      <input
                        type="email"
                        id="email"
                        autofocus
                        onChange={(e) =>
                          setFormData({ ...formData, email: e.target.value })
                        }
                        placeholder="Enter Your Email"
                        className="px-4 py-2 transition duration-300 border border-gray-300 rounded focus:border-transparent focus:outline-none focus:ring-4 focus:ring-blue-200 w-full mb-4"
                      />
                      <input
                        type="password"
                        id="password"
                        autofocus
                        onChange={(e) =>
                          setFormData({ ...formData, password: e.target.value })
                        }
                        placeholder="Enter Your Password"
                        className="px-4 py-2 transition duration-300 border border-gray-300 rounded focus:border-transparent focus:outline-none focus:ring-4 focus:ring-blue-200 w-full mb-4"
                      />

                      {error ? (
                        <div className="flex items-center justify-center">
                          <span className="text-red-500">{error}</span>
                        </div>
                      ) : (
                        <div></div>
                      )}

                      {/* <!--Submit button--> */}
                      <div className="mb-12 pb-1 pt-1 text-center">
                        <button
                          className="mb-3 inline-block w-full rounded px-6 pb-2 pt-2.5 text-xs font-medium uppercase leading-normal text-white shadow-[0_4px_9px_-4px_rgba(0,0,0,0.2)] transition duration-150 ease-in-out hover:shadow-[0_8px_9px_-4px_rgba(0,0,0,0.1),0_4px_18px_0_rgba(0,0,0,0.2)] focus:shadow-[0_8px_9px_-4px_rgba(0,0,0,0.1),0_4px_18px_0_rgba(0,0,0,0.2)] focus:outline-none focus:ring-0 active:shadow-[0_8px_9px_-4px_rgba(0,0,0,0.1),0_4px_18px_0_rgba(0,0,0,0.2)] bg-black"
                          type="submit"
                          // style={{
                          //   background:
                          //     "linear-gradient(to right, #ee7724, #d8363a, #dd3675, #b44593)",
                          // }}
                        >
                          Register Account
                        </button>

                        {/* <!--Forgot password link--> */}
                        {/* <Link to="/forgatepassword">Forgot password?</Link> */}
                      </div>

                      {/* <!--Register button--> */}
                      {/* <div className="flex items-center justify-between pb-6">
                        <p className="mb-0 mr-2">Don't have an account?</p>
                        <TERipple rippleColor="light">
                          <button
                            // type="button"
                            onClick={goToRegiserPage}
                            className="inline-block rounded border-2 border-danger px-6 pb-[6px] pt-2 text-xs font-medium uppercase leading-normal text-danger transition duration-150 ease-in-out hover:border-danger-600 hover:bg-neutral-500 hover:bg-opacity-10 hover:text-danger-600 focus:border-danger-600 focus:text-danger-600 focus:outline-none focus:ring-0 active:border-danger-700 active:text-danger-700 dark:hover:bg-neutral-100 dark:hover:bg-opacity-10"
                          >
                            Register
                          </button>
                        </TERipple>
                      </div> */}
                    </form>
                  </div>
                </div>

                {/* <!-- Right column container with background and description--> */}
                <div
                  className="flex items-center rounded-b-lg lg:w-6/12 lg:rounded-r-lg lg:rounded-bl-none"
                  style={{
                    background:
                      "linear-gradient(to right, #ee7724, #d8363a, #dd3675, #b44593)",
                  }}
                >
                  <div className="px-4 py-6 text-white md:mx-6 md:p-12">
                    <h4 className="mb-6 text-xl font-semibold">
                      We are more than just a company
                    </h4>
                    <p className="text-sm">
                      We are the best Software & IT Service Company in Dhaka
                      Bangladesh. Because we have a highly skilled Website
                      development SEO & Digital Marketing team in Dhaka. We have
                      a 24/7 support team. We are providing our clients full
                      E-Commerce support, Business plans for product sourcing
                      and business planning. Our Software firm bd has been done
                      successfully Ecourier to corporate website and software
                      development.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default SignUp;
