import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { subCategoryFetching } from "../features/category/subCategorySlice";
import { useParams } from "react-router-dom";
import Card from "../components/productCards/Card";

const SubCategoryPage = () => {
  const { items: data } = useSelector((state) => state.subCategoryProduct);
  // const [subCategoryProduct, setSubCategoryProduct] = useState([]);
  const { id } = useParams();

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(subCategoryFetching(id));
  });

  return (
    <div className="wrapper min-h-screen mt-5">
      <div className="flex flex-wrap items-center justify-center gap-3">
        {data?.length !== 0 ? (
          data.map((product) => <Card key={product.id} product={product} />)
        ) : (
          <p className="col-span-full text-center">No Product Found</p>
        )}
      </div>
    </div>
  );
};

export default SubCategoryPage;
