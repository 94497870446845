import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";

const DashboardDetails = () => {
  const session = useSelector((state) => state.user.userAndToken);

  const [totalOrder, setTotalOrder] = useState({});
  const [totalAmount, setTotalAmount] = useState({});
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);
      try {
        const res = await fetch(
          `${process.env.REACT_APP_URL}/api/customer-order/${session?.user?.email}`
        );
        if (res.ok) {
          const data = await res.json();
          setTotalOrder(data);
          setLoading(false);
        }
      } catch (error) {
        console.log(error.message);
      }
    };
    fetchData();
  }, [session?.user?.email]);

  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);
      try {
        const res = await fetch(
          `${process.env.REACT_APP_URL}/api/customer-amount/${session?.user?.email}`
        );
        const data = await res.json();
        setTotalAmount(data);
        setLoading(false);
      } catch (error) {
        console.log(error.message);
      }
    };
    fetchData();
  }, [session?.user?.email]);
  return (
    <div>
      <div className="bg-white rounded-md overflow-hidden p-5">
        <div className="flex flex-wrap items-center justify-center gap-10">
          <div className="bg-[#C3529F] w-50 h-40 p-3 flex flex-col items-center justify-center">
            {!loading ? (
              <span className="text-white text-2xl font-semibold">
                Your Total Order
              </span>
            ) : (
              <span className="text-white text-2xl font-semibold">
                loading you data...
              </span>
            )}

            <span className="text-white text-2xl font-semibold">
              <span> {totalOrder.order}</span>
            </span>
          </div>
          <div className="bg-[#5547BB] w-50 h-40 p-3 flex flex-col items-center justify-center">
            {!loading ? (
              <span className="text-white text-2xl font-semibold">
                Your Total buy
              </span>
            ) : (
              <span className="text-white text-2xl font-semibold">
                loading you data...
              </span>
            )}
            <span className="text-white text-2xl font-semibold">
              <span> {totalAmount.amount}</span>
            </span>
          </div>
          {/* <div className="bg-[#6791D9] h-40 p-5 flex flex-col items-center justify-center">
            <span className="text-white text-2xl font-semibold">
              Your Wish Products
            </span>
          </div> */}
        </div>
      </div>
    </div>
  );
};

export default DashboardDetails;
