import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, NavLink, Outlet, useNavigate } from "react-router-dom";
import { logout } from "../../features/auth/authSlice";
import { RxDashboard } from "react-icons/rx";
import { FaSellsy } from "react-icons/fa";
import { FaUserEdit } from "react-icons/fa";

const DashbordPage = () => {
  const session = useSelector((state) => state.user.userAndToken);

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const navActive = ({ isActive }) => {
    return {
      color: isActive ? "#D89307" : null,
      Font: isActive ? "font-bold" : null,
    };
  };

  const logoutHandelar = () => {
    dispatch(logout());
    navigate("/login");
  };

  return (
    <div className="container mx-auto mt-5">
      <div className="grid md:grid-cols-[30%_70%] gap-5">
        <div className="bg-white flex flex-col">
          <div className="user_image_email flex items-center gap-5  bg-white shadow-sm px-3 py-3">
            <div className="w-10 h-10 overflow-hidden rounded-full">
              <img
                src={`${process.env.REACT_APP_URL}/uploads/users/${session?.user?.avatar}`}
                alt=""
                className="w-full h-full object-cover"
              />
            </div>
            <span>{session?.user?.name}</span>
          </div>
          <div>
            <NavLink
              style={navActive}
              to={"/dashbord/details"}
              className="w-full  p-5 flex gap-2 items-center"
            >
              <span>
                <RxDashboard />
              </span>
              <span>Dashboard</span>
            </NavLink>

            <NavLink
              style={navActive}
              to={"/dashbord/userpurchasehistory"}
              className="w-full  p-5 flex gap-2 items-center"
            >
              <span>
                <FaSellsy />
              </span>
              <span> Purchase History</span>
            </NavLink>

            <NavLink
              style={navActive}
              to={"/dashbord/userdetails"}
              className="w-full  p-5 flex gap-2 items-center"
            >
              <span>
                <FaUserEdit />
              </span>
              <span> Manage Profile</span>
            </NavLink>
            <div className="flex items-center justify-center my-5">
              <button
                onClick={logoutHandelar}
                className="bg-red-600 hover:bg-[#3A3A3A] py-3 px-8  rounded-md  text-white duration-300"
              >
                Logout
              </button>
            </div>
          </div>
        </div>
        <Outlet session={session} />
      </div>
    </div>
  );
};

export default DashbordPage;

// <div className="bg-white rounded-md overflow-hidden">
// <div className="w-full flex flex-col gap-2 items-center justify-center py-10">
//   <div className="w-20 h-20 overflow-hidden rounded-full">
//     <img
//       src={`${process.env.REACT_APP_URL}/uploads/users/${session?.user?.avatar}`}
//       alt=""
//       className="w-full h-full object-cover"
//     />
//   </div>

//   <div className="text-black">
//     <span>{session?.user?.email}</span>
//   </div>
// </div>
// </div>

{
  /* <div className="">
<div className="grid gap-3">
  <Link
    to={"/dashbord/details"}
    className="w-full bg-red-100 p-5 flex gap-2 items-center"
  >
    <span>
      <RxDashboard />
    </span>
    <span>Dashboard</span>
  </Link>
  <Link
    to={"/dashbord/userpurchasehistory"}
    className="w-full bg-red-100 p-5 flex gap-2 items-center"
  >
    <span>
      <FaSellsy />
    </span>
    <span> Purchase History</span>
  </Link>
  <Link
    to={"/dashbord/userdetails"}
    className="w-full bg-red-100 p-5 flex gap-2 items-center"
  >
    <span>
      <FaUserEdit />
    </span>
    <span> Manage Profile</span>
  </Link>
</div>
<div className="flex items-center justify-center my-5">
  <button
    onClick={logoutHandelar}
    className="bg-red-600 hover:bg-[#3A3A3A] py-3 px-8  rounded-md  text-white duration-300"
  >
    Logout
  </button>
</div>
</div> */
}
