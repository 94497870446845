import SectionTitle from "../common/SectionTitle";
import { Link } from "react-router-dom";
import { useSelector } from "react-redux";

const CategoryCard = () => {
  const { items: data } = useSelector((state) => state.category);

  return (
    <div className="">
      <SectionTitle title={"Categories"} />
      <div className="w-full bg-white">
        <div className="flex flex-wrap justify-center items-center gap-5">
          {data?.map((category) => (
            <div
              key={category?.id}
              className="px-5 py-3 border-[1px] w-[80px] md:w-[120px] hover:shadow-lg duration-300"
            >
              <Link
                to={`/categorypage/${category?.id}`}
                className="flex flex-col items-center justify-center"
              >
                <div className="w-[60px] h-[60px] md:w-[80px] md:h-[80px] overflow-hidden">
                  <img
                    src={`${process.env.REACT_APP_URL}/uploads/category/${category?.banner}`}
                    alt=""
                    className="object-cover w-full h-full"
                  />
                </div>
                <div className="category_content relative overflow-hidden">
                  <p className="text-center mt-[10px] text-[#212121] truncate">
                    {category?.name}
                  </p>
                </div>
              </Link>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default CategoryCard;
