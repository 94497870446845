import Footer from "./components/footer/Footer";
import { Navigate, Route, Routes } from "react-router-dom";
import Home from "./pages/Home";
import ProductDetails from "./pages/ProductDetails";
import AddToCart from "./pages/AddToCart";
import ScrollToTop from "./components/common/ScrollToTop";

import Checkout from "./pages/Checkout";
import { useSelector } from "react-redux";
import SuccessPage from "./pages/SuccessPage";

import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import { useState } from "react";
import CartButton from "./components/productCards/CardButton";
import AddToCartCom from "./components/addToCart/AddToCartCom";
import About from "./pages/About";
import Contact from "./pages/Contact";

import CategoryPage from "./pages/CategoryPage";

import Login from "./pages/Login";

import Navbar from "./components/Navbar/Navbar";
import DashbordPage from "./pages/dashboard/DashbordPage";
import SignUp from "./pages/SignUp";
import UserDetails from "./pages/dashboard/UserDetails";
import UserHistory from "./pages/dashboard/UserHistory";
import DashboardDetails from "./pages/dashboard/DashboardDetails";
import OrderDetails from "./pages/dashboard/OrderDetails";
import ForgatePassword from "./pages/user/ForgatePassword";
import ConfirmPassword from "./pages/user/ConfirmPassword";
import MobileNav from "./components/Navbar/MobileNav";
import SubCategoryPage from "./pages/SubCategoryPage";

function App() {
  const { cartItems: data, cartTotalAmount } = useSelector(
    (state) => state.cart
  );

  const [sideNav, setSideNav] = useState(false);
  // const [loading, setLoading] = useState(false);
  // const { token: usertoken } = useSelector((state) => state.user);
  const session = useSelector((state) => state.user.userAndToken);

  const [orderId, setOrderId] = useState("");
  const [state, setState] = useState(false);

  const handleAddToCart = () => {
    setState(!state);
  };

  const handleMenu = () => {
    document.body.style.top = `-${window.scrollY}px`;
    document.body.style.position = "fixed";

    document.body.style.left = 0;
    document.body.style.right = 0;
    document.body.style.bottom = 0;
    setSideNav(() => !sideNav);
  };

  return (
    <>
      <div className=" bg-[#F2F3F8] ">
        <div className="min-h-screen">
          <Navbar />
          <ToastContainer />

          <ScrollToTop>
            <Routes>
              <Route path={"/"} element={<Home />} />
              <Route path="/productdetails" element={<ProductDetails />} />
              <Route
                exact
                path="/productdetails/:id"
                element={<ProductDetails />}
              />

              <Route path="/categorypage" element={<CategoryPage />} />
              <Route path="/categorypage/:id" element={<CategoryPage />} />
              <Route path="/subcategory/:id" element={<SubCategoryPage />} />
              <Route path="/addtocart" element={<AddToCart />} />
              <Route
                path="/checkout"
                element={<Checkout setOrderId={setOrderId} />}
              />
              <Route
                path="/successpage"
                element={<SuccessPage orderId={orderId} />}
              />

              <Route path="/about-us/:id" element={<About />} />
              <Route path="/contact-us/:id" element={<Contact />} />

              {session?.user ? (
                <>
                  <Route path="/dashbord" element={<DashbordPage />}>
                    <Route
                      index
                      path="/dashbord/details"
                      element={<DashboardDetails />}
                    />
                    <Route
                      path="/dashbord/userdetails"
                      element={<UserDetails />}
                    />
                    <Route
                      path="/dashbord/userpurchasehistory"
                      element={<UserHistory />}
                    />
                    <Route
                      path="/dashbord/orderdetails/:id"
                      element={<OrderDetails />}
                    />
                  </Route>
                </>
              ) : (
                <>
                  <Route path="/login" element={<Login />} />
                  <Route path="/register" element={<SignUp />} />
                  <Route
                    path="/forgatepassword"
                    element={<ForgatePassword />}
                  />
                  <Route
                    path="/confirmpassword"
                    element={<ConfirmPassword />}
                  />
                </>
              )}
              {/* <Route path="/login" element={<Login />} /> */}
              {/* <Route path="/dashbord" element={<DashbordPage />} /> */}
            </Routes>

            <button
              onClick={handleAddToCart}
              className="fixed top-[45%] right-0 z-[9999]"
            >
              <CartButton data={data} cartTotalAmount={cartTotalAmount} />
            </button>
            {state && <AddToCartCom state={state} setState={setState} />}
            {/* {modla && <OrderNowModal />} */}
          </ScrollToTop>
        </div>
        <MobileNav handleMenu={handleMenu} />
        <Footer />
      </div>
    </>
  );
}

export default App;
