import { createSlice } from "@reduxjs/toolkit";
import toast from "react-hot-toast";

const initialState = {
  resetCode: null,
};

export const emailCodeSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    setCode: (state, action) => {
      state.resetCode = action.payload;

      alert("A code Sent to Your Email");

      // toast.success("A code Sent to Your Email", {
      //   position: "bottom-left",
      //   autoClose: 5000,
      //   hideProgressBar: false,
      //   closeOnClick: true,
      //   pauseOnHover: true,
      //   draggable: true,
      //   progress: undefined,
      //   theme: "light",
      // });
    },

    removeCode: (state) => {
      state.resetCode = null;
    },
  },

  extraReducers: (builder) => {},
});

export const { setCode, removeCode } = emailCodeSlice.actions;
export default emailCodeSlice.reducer;
