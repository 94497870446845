import React, { useState } from "react";
import { useSelector } from "react-redux";
import {
  MdOutlineKeyboardArrowDown,
  MdOutlineKeyboardArrowUp,
} from "react-icons/md";

import BannerSlider from "./BannerSlider";
import SubCat from "../Category-components/SubCat";

const HeroSection = () => {
  const { items: subcategories } = useSelector((state) => state.subCatgory);
  // const subcategory = useSelector((state) => state?.subCatgory);

  const [activeMenuIndex, setActiveMenuIndex] = useState(-1);
  const [menuLeaveTimeout, setMenuLeaveTimeout] = useState(null);
  const [expandedMenuIndex, setExpandedMenuIndex] = useState(-1);
  // const [showCategoryMenu, setshowCategoryMenu] = useState(false);

  const handleMenuHover = (index) => {
    setActiveMenuIndex(index);
    clearTimeout(menuLeaveTimeout);
  };

  const handleMenuLeave = () => {
    const timeout = setTimeout(() => {
      setActiveMenuIndex(-1);
    }, 200);
    setMenuLeaveTimeout(timeout);
  };

  const getSubcategoriesByCategoryId = (categoryId) => {
    return subcategories?.filter(
      (subcategory) => +subcategory?.category_id === categoryId
    );
  };
  const getMenuIcon = (index) => {
    if (activeMenuIndex === index || expandedMenuIndex === index) {
      return <MdOutlineKeyboardArrowUp />;
    }
    return <MdOutlineKeyboardArrowDown />;
  };

  return (
    <div className="grid lg:grid-cols-[20%_75%] gap-10 mt-3">
      <div className="hidden lg:block">
        <SubCat
          handleMenuHover={handleMenuHover}
          getMenuIcon={getMenuIcon}
          getSubcategoriesByCategoryId={getSubcategoriesByCategoryId}
          handleMenuLeave={handleMenuLeave}
          activeMenuIndex={activeMenuIndex}
          expandedMenuIndex={expandedMenuIndex}
        />
      </div>
      <div>
        <BannerSlider />
      </div>
    </div>
  );
};

export default HeroSection;
