import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";

const initialState = {
  items: [],
  status: null,
};

//data fetching

export const subCategoryIdFetching = createAsyncThunk(
  "subcategoryId/subCategoryIdFetching",
  async (id) => {
    const res = await axios.get(
      `${process.env.REACT_APP_URL}/api-subcategories/${id}`
    );
    return res.data;
  }
);

export const subCategoryIdSlice = createSlice({
  name: "category",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(subCategoryIdFetching.pending, (state, action) => {
      state.status = "loading...";
    });
    builder.addCase(subCategoryIdFetching.fulfilled, (state, action) => {
      state.status = "";
      state.items = action.payload;
    });
    builder.addCase(subCategoryIdFetching.rejected, (state, action) => {
      state.status = "Something Went Wrong";
    });
  },
});

export default subCategoryIdSlice.reducer;
