import React, { useCallback, useState } from "react";
import { axiosPost } from "../../lib/axiosPost";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { setCode } from "../../features/auth/forgatePasswordSlice";
import toast from "react-hot-toast";

const ForgatePassword = () => {
  const [formData, setFormData] = useState({
    email: "",
  });

  const navigate = useNavigate();
  const dispatch = useDispatch();

  const handleSubmit = useCallback(
    async (e) => {
      e.preventDefault();

      const data = await axiosPost("/api/email-reset", formData);

      if (data) {
        setFormData({
          email: "",
        });

        // toast.success("A code Sent to Your Email", {
        //   position: "bottom-left",
        //   autoClose: 5000,
        //   hideProgressBar: false,
        //   closeOnClick: true,
        //   pauseOnHover: true,
        //   draggable: true,
        //   progress: undefined,
        //   theme: "light",
        // });

        dispatch(setCode(data));

        navigate("/confirmpassword");
        console.log(data);
      } else {
        // setIsLoading(false);
      }
    },
    [formData, dispatch, navigate]
  );
  return (
    <section className="container mx-auto  h-full bg-neutral-200 dark:bg-neutral-700 ">
      <div className="container h-full p-10">
        <div className="g-6 flex h-full flex-wrap items-center justify-center text-neutral-800 dark:text-neutral-200">
          <div className="w-full">
            <div className="block rounded-lg bg-white shadow-lg dark:bg-neutral-800">
              <div className="g-0 lg:flex lg:flex-wrap">
                {/* <!-- Left column container--> */}
                <div className="px-4 md:px-0 lg:w-6/12">
                  <div className="md:mx-6 md:p-12">
                    {/* <!--Logo--> */}
                    <div className="text-center">
                      <img
                        className="mx-auto w-48"
                        src="https://tecdn.b-cdn.net/img/Photos/new-templates/bootstrap-login-form/lotus.webp"
                        alt="logo"
                      />
                      <h4 className="mb-12 mt-1 pb-1 text-xl font-semibold">
                        We are The Lotus Team
                      </h4>
                    </div>

                    <form onSubmit={handleSubmit}>
                      <p className="mb-4">Please Confirm Your Email</p>

                      <input
                        type="email"
                        id="email"
                        autofocus
                        onChange={(e) =>
                          setFormData({ ...formData, email: e.target.value })
                        }
                        placeholder="Enter Your Email"
                        className="px-4 py-2 transition duration-300 border border-gray-300 rounded focus:border-transparent focus:outline-none focus:ring-4 focus:ring-blue-200 w-full mb-4"
                      />

                      {/* <!--Submit button--> */}
                      <div className="mb-12 pb-1 pt-1 text-center">
                        <button
                          className="mb-3 inline-block w-full rounded px-6 pb-2 pt-2.5 text-xs font-medium uppercase leading-normal text-white shadow-[0_4px_9px_-4px_rgba(0,0,0,0.2)] transition duration-150 ease-in-out hover:shadow-[0_8px_9px_-4px_rgba(0,0,0,0.1),0_4px_18px_0_rgba(0,0,0,0.2)] focus:shadow-[0_8px_9px_-4px_rgba(0,0,0,0.1),0_4px_18px_0_rgba(0,0,0,0.2)] focus:outline-none focus:ring-0 active:shadow-[0_8px_9px_-4px_rgba(0,0,0,0.1),0_4px_18px_0_rgba(0,0,0,0.2)]"
                          type="submit"
                          style={{
                            background:
                              "linear-gradient(to right, #ee7724, #d8363a, #dd3675, #b44593)",
                          }}
                        >
                          Confirm Email
                        </button>
                      </div>
                    </form>
                  </div>
                </div>

                {/* <!-- Right column container with background and description--> */}
                <div
                  className="flex items-center rounded-b-lg lg:w-6/12 lg:rounded-r-lg lg:rounded-bl-none"
                  style={{
                    background:
                      "linear-gradient(to right, #ee7724, #d8363a, #dd3675, #b44593)",
                  }}
                >
                  <div className="px-4 py-6 text-white md:mx-6 md:p-12">
                    <h4 className="mb-6 text-xl font-semibold">
                      We are more than just a company
                    </h4>
                    <p className="text-sm">
                      Lorem ipsum dolor sit amet, consectetur adipisicing elit,
                      sed do eiusmod tempor incididunt ut labore et dolore magna
                      aliqua. Ut enim ad minim veniam, quis nostrud exercitation
                      ullamco laboris nisi ut aliquip ex ea commodo consequat.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>

    // confirm Email
    // <div class="flex items-center min-h-screen p-4 bg-gray-100 lg:justify-center">
    //   <div class="flex flex-col overflow-hidden bg-white rounded-md shadow-lg">
    //     <div class="p-5 bg-white md:flex-1">
    //       <h3 class="my-4 text-2xl font-semibold text-gray-700">
    //         Entar Your Email
    //       </h3>
    //       <form onSubmit={handleSubmit} class="flex flex-col space-y-5">
    //         <div class="flex flex-col space-y-1">
    //           <label for="email" class="text-sm font-semibold text-gray-500">
    //             Email address
    //           </label>
    //           <input
    //             type="email"
    //             id="email"
    //             autofocus
    //             onChange={(e) =>
    //               setFormData({ ...formData, email: e.target.value })
    //             }
    //             class="px-4 py-2 transition duration-300 border border-gray-300 rounded focus:border-transparent focus:outline-none focus:ring-4 focus:ring-blue-200"
    //           />
    //         </div>

    //         {/* {error ? (
    //           <div className="flex items-center justify-center">
    //             <span className="text-red-500">{error}</span>
    //           </div>
    //         ) : (
    //           <div></div>
    //         )} */}

    //         <div>
    //           <button
    //             type="submit"
    //             class="w-full px-4 py-2 text-lg font-semibold text-white transition-colors duration-300 bg-blue-500 rounded-md shadow hover:bg-blue-600 focus:outline-none focus:ring-blue-200 focus:ring-4"
    //           >
    //             Submit
    //           </button>
    //         </div>
    //       </form>
    //     </div>
    //   </div>
    // </div>
  );
};

export default ForgatePassword;
