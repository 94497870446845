import { combineReducers, configureStore } from "@reduxjs/toolkit";

import {
  persistStore,
  persistReducer,
  FLUSH,
  REHYDRATE,
  PAUSE,
  PERSIST,
  PURGE,
  REGISTER,
} from "redux-persist";
import storage from "redux-persist/lib/storage";

import productReducer, {
  productFetching,
} from "../features/products/productSlice";
import cartReducer from "../features/products/cartSlice";
import categoryReducer, {
  categoryFetching,
} from "../features/products/categorySlice";
import bestSellingReducer, {
  bestSellingFetching,
} from "../features/products/bestSellingSlice";
import footerDetailsReducer, {
  footerDetails,
} from "../features/products/footerAboutSlice";

import bannerReducer, { bannerFatching } from "../features/banner/bannerSlice";

import logoReducer, { logoFatching } from "../features/logo/logoSlice";
import homeBannerReducer, {
  homeBannerFetching,
} from "../features/banner/homeBannerSlice";

import sizeAttributesReducer, {
  sizeAttributeFatching,
} from "../features/attributes/sizeAttributesSlice";
import authReducer from "../features/auth/authSlice";
import subCategoryReducer, {
  subCategoryFetching,
} from "../features/category/subCategorySlice";

import forgatePasswordReducer from "../features/auth/forgatePasswordSlice";
import singleProductReducer, {
  singleProductFetching,
} from "../features/products/singleProductSlice";
import thambImageReducer, {
  thumbImageFetching,
} from "../features/products/thambImageSlice";
import subCategoryIdSlice, {
  subCategoryIdFetching,
} from "../features/category/subCategoryProduct";

const persistConfig = {
  key: "root",
  version: 1,
  storage,
};

const rootReducer = combineReducers({
  products: productReducer,
  cart: cartReducer,
  category: categoryReducer,
  bestSell: bestSellingReducer,
  footerDetails: footerDetailsReducer,
  banner: bannerReducer,
  logo: logoReducer,
  homeBanner: homeBannerReducer,
  sizes: sizeAttributesReducer,
  user: authReducer,
  reSetCode: forgatePasswordReducer,
  subCatgory: subCategoryReducer,
  singleProduct: singleProductReducer,
  // thumbImage: thumbImageReducer,
  subCategoryProduct: subCategoryIdSlice,
  thumbImage: thambImageReducer,
});

const persistedReducer = persistReducer(persistConfig, rootReducer);

export const store = configureStore({
  reducer: persistedReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: {
        ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
      },
    }),
});

export const persistor = persistStore(store);

store.dispatch(categoryFetching());
store.dispatch(subCategoryFetching());
// store.dispatch(singleProductFetching());
// store.dispatch(thumbImageFetching());
store.dispatch(productFetching());
store.dispatch(subCategoryIdFetching());

store.dispatch(bestSellingFetching());
store.dispatch(footerDetails());

store.dispatch(bannerFatching());
store.dispatch(logoFatching());
store.dispatch(homeBannerFetching());
store.dispatch(sizeAttributeFatching());
