import Banner from "../components/common/Banner";

import CategoryCard from "../components/Category-components/CategoryCard";
import HeroSection from "../components/Hero/HeroSection";

import BestSelling from "./BestSelling";

import { useState } from "react";
import { useSelector } from "react-redux";
import CombineData from "../components/Category-components/CombineData";

const Home = () => {
  const { items: data } = useSelector((state) => state.products);
  const { items: categoryData } = useSelector((state) => state.category);

  const combianData = categoryData.map((category) => ({
    ...category,
    products: data.filter((data) => category.id === +data?.category_id),
  }));

  console.log(combianData);

  const [visible, setVisible] = useState(18);

  const handleSeeMore = () => {
    setVisible((prevValue) => prevValue + 12);
  };

  const handleless = () => {
    setVisible((prevValue) => prevValue - 6);
  };

  return (
    <div className="container mx-auto">
      <HeroSection />
      <CategoryCard />
      <CombineData combianData={combianData} />

      <BestSelling
        visible={visible}
        handleSeeMore={handleSeeMore}
        handleless={handleless}
      />
      <Banner />
    </div>
  );
};

export default Home;
