import { useSelector } from "react-redux";
import SectionTitle from "../components/common/SectionTitle";
import Card from "../components/productCards/Card";

import "swiper/swiper.min.css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import "swiper/css/hash-navigation";

const BestSelling = ({ visible, handleSeeMore, handleless }) => {
  const { items: data } = useSelector((state) => state.products);

  return (
    <div className="">
      <div className="flex items-center justify-center">
        <SectionTitle title={"Just For You"} />
      </div>

      <div className="flex flex-wrap justify-center gap-5">
        {data?.length > 0 &&
          data
            .slice(0, visible)
            .map((product) => <Card key={product.id} product={product} />)}
      </div>

      <div className="flex items-center justify-center my-8">
        {data.length > visible ? (
          <button
            className="border-[1px] border-[#F85606] text-[#F85606]  px-10 py-3 rounded-md duration-300"
            onClick={handleSeeMore}
          >
            Load More
          </button>
        ) : null}
        {data?.length === visible && (
          <button
            className="bg-[#16995D] text-white hover:bg-green-600 px-5 py-3 rounded-md duration-300"
            onClick={handleless}
          >
            Load less
          </button>
        )}
      </div>
    </div>
  );
};

export default BestSelling;
