import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/pagination";
// import required modules
import { Autoplay, HashNavigation, Navigation } from "swiper";
import { useSelector } from "react-redux";

const BannerSlider = () => {
  const { items: data } = useSelector((state) => state.banner);

  return (
    <div className="">
      <div className="banner-slider">
        <Swiper
          slidesPerView={"auto"}
          spaceBetween={30}
          autoplay={{
            delay: 3000,
            disableOnInteraction: false,
          }}
          speed={700}
          hashNavigation
          loop={true}
          modules={[Autoplay, Navigation, HashNavigation]}
          className="mySwiper h-full"
        >
          {data.map((banner) => (
            <div key={banner.id}>
              <SwiperSlide>
                <div className="w-full h-full overflow-hidden">
                  <img
                    src={`${process.env.REACT_APP_URL}/public/uploads/slider/${banner?.slider}`}
                    alt={banner.alt}
                    className={` h-full  w-full  transition-opacity duration-500 object-cover `}
                  />
                </div>
              </SwiperSlide>
            </div>
          ))}
        </Swiper>
      </div>
    </div>
  );
};

export default BannerSlider;
