import moment from "moment";
import React from "react";
import { IoEyeOutline } from "react-icons/io5";
import OrderDetails from "../../pages/dashboard/OrderDetails";
import { Link } from "react-router-dom";

const UserOrderCard = ({
  invoice,
  handleOpen,
  setModal,
  modal,
  handleClose,
}) => {
  return (
    <div className="grid grid-cols-6">
      <div>
        <div>
          <span className="flex flex-col gap-3">
            <div k>{invoice?.code}</div>
          </span>
        </div>
      </div>
      <div>
        <span className="flex flex-col gap-3">
          {moment(invoice?.created_at).format("DD MMM YY. hh:mm A")}
        </span>
      </div>
      <div>
        <span className="flex flex-col gap-3">
          <div>{invoice?.grand_total}</div>
        </span>
      </div>
      <div>
        <span className="flex flex-col gap-3">
          <div>{invoice?.delivery_status}</div>
        </span>
      </div>
      <div>
        <span className="flex flex-col gap-3">
          <div>{invoice?.payment_status}</div>
        </span>
      </div>
      <div>
        <span className="flex flex-col gap-3">
          <div>
            <Link
              to={`/dashbord/orderdetails/${invoice?.id}`}
              // onClick={handleOpen}
              className="bg-blue-300/40 rounded-full overflow-hidden h-8 w-8 flex items-center justify-center"
            >
              <span>
                <IoEyeOutline className="text-blue-500" />
              </span>
            </Link>
            {/* {modal && (
              <>
                <OrderDetails
                  invoice={invoice}
                  modal={modal}
                  setModal={setModal}
                  handleClose={handleClose}
                />
              </>
            )} */}
          </div>
        </span>
      </div>
    </div>
  );
};

export default UserOrderCard;
