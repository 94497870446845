import { useEffect, useState } from "react";

import SectionTitle from "../components/common/SectionTitle";
import { useParams } from "react-router-dom";
import Card from "../components/productCards/Card";
import { useSelector } from "react-redux";

const CategoryPage = () => {
  const [categoryProduct, setCategoryProduct] = useState([]);

  const { items: data } = useSelector((state) => state.category);
  console.log(categoryProduct);
  const { id } = useParams();

  useEffect(() => {
    const fetchData = async () => {
      try {
        const res = await fetch(
          `${process.env.REACT_APP_URL}/api-categories/${id}`
        );
        const data = await res.json();
        setCategoryProduct(data);
      } catch (error) {
        console.log(error.message);
      }
    };
    fetchData();
  }, [id]);
  return (
    <div className="wrapper min-h-screen mt-5">
      <div className="flex flex-wrap items-center justify-center gap-3">
        {categoryProduct?.length !== 0 ? (
          categoryProduct.map((product) => (
            <Card key={product.id} product={product} />
          ))
        ) : (
          <p className="col-span-full text-center">No Product Found</p>
        )}
      </div>
    </div>
  );
};

export default CategoryPage;
