import axios from "axios";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { RxCross1 } from "react-icons/rx";
import { useNavigate, useParams } from "react-router-dom";

const OrderDetails = () => {
  const [order, setOrder] = useState([]);

  console.log(order);

  const { id } = useParams();

  const [modal, setModal] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    const singleProduct = async () => {
      const res = await axios.get(
        `${process.env.REACT_APP_URL}/api/single-invoice/${id}`
      );

      setOrder(res.data);
    };
    singleProduct();
  }, [id]);

  const handleClose = () => {
    navigate("/dashbord/userpurchasehistory");
  };

  return (
    <div className="container mx-auto">
      <div
        onClick={handleClose}
        className="bg-black/40  z-[9998]  fixed top-0 left-0 right-0 bottom-0 w-full h-full overflow-hidden"
      ></div>
      <div className="p-2 z-[9999] bg-white  fixed  shadow-xl top-0 right-0 left-0 bottom-0 w-full h-auto mr-5  md:container md:mx-auto my-10 overflow-y-scroll">
        {order?.map((order) => (
          <div key={order?.id}>
            <div className="flex items-center justify-between h-10 py-5 border-b-2">
              <span className="flex gap-5">
                <span>Order id:</span>
                <span>{order?.code}</span>
              </span>
              <span>
                <button
                  onClick={handleClose}
                  className="my-2 flex items-end justify-end"
                >
                  <RxCross1 />
                </button>
              </span>
            </div>

            <div className="p-5">
              <div className="border-2">
                <div className="h-10 flex items-center border-b-2">
                  <span>Order Summary</span>
                </div>

                <div className="px-20 py-16 grid grid-cols-[50%_50%] gap-5 ">
                  <div className="grid gap-3">
                    <div className="grid grid-cols-2 items-center">
                      <span>Order code:</span>
                      <span>{order?.code}</span>
                    </div>
                    <div className="grid grid-cols-2 items-center ">
                      <span>Customer:</span>
                      <span>{order?.name}</span>
                    </div>
                    <div className="grid grid-cols-2 items-center ">
                      <span>Email:</span>
                      <span>{order?.email}</span>
                    </div>
                    <div className="grid grid-cols-2 items-center ">
                      <span>shooping Address:</span>
                      <span>{order?.address}</span>
                    </div>
                  </div>
                  <div className="grid gap-3">
                    <div className="grid grid-cols-2 items-center">
                      <span>Order date:</span>
                      {moment(order?.created_at).format("DD MMM YY. hh:mm A")}
                    </div>
                    <div className="grid grid-cols-2 items-center">
                      <span>Phone:</span>
                      <span>{order?.phone}</span>
                    </div>
                    <div className="grid grid-cols-2 items-center">
                      <span>Total order amount:</span>
                      <span>{order?.grand_total}</span>
                    </div>
                    <div className="grid grid-cols-2 items-center">
                      <span>Payment method:</span>
                      <span>{order?.shipping_type}</span>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="p-5">
              <div className="grid grid-cols-[70%_30%] gap-3">
                <div className=" border-2">
                  <div className="flex items-center justify-center text-center py-3  border-b-2">
                    Order Details
                  </div>
                  <div className="px-3 py-3 grid grid-cols-4">
                    <div>Product</div>
                    <div>Variation</div>
                    <div>Quantity</div>
                    <div>Price</div>
                  </div>
                  {/*  */}
                  {JSON.parse(order?.items).map((item) => (
                    <div className="px-3 grid grid-cols-4 py-3">
                      <div>{item?.name}</div>
                      <div>{item?.sizeAtr}</div>
                      <div>{item?.cartQuantity}</div>
                      <div>{item?.response}</div>
                    </div>
                  ))}
                </div>
                <div className=" border-2">
                  <div className="flex items-center justify-center text-center py-3  border-b-2">
                    Order Ammount
                  </div>
                  <div className="px-3 py-3 grid">
                    {/* <div className="grid grid-cols-2 gap-3">
                      <span> Subtotal:</span>
                    </div> */}
                    {/* <div className="grid grid-cols-2 gap-3">
                          <span>discount:</span>
                          <span>{order?.shipping_cost}</span>
                        </div> */}
                    <div className="grid grid-cols-2 gap-3">
                      <span> Shipping:</span>
                      <span>{order?.shipping_cost}</span>
                    </div>
                    <div className="grid grid-cols-2 gap-3">
                      <span> Total:</span>
                      <span>{order?.grand_total}</span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default OrderDetails;
