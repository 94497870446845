import React, { useCallback, useEffect, useState } from "react";

import { Parser } from "html-to-react";

import axios from "axios";
import { useNavigate, useParams } from "react-router-dom";
import {
  AiOutlineMinus,
  AiOutlineMinusCircle,
  AiOutlinePlusCircle,
} from "react-icons/ai";

import { BsMessenger, BsPlus } from "react-icons/bs";

import {
  addToSingleCart,
  addtoCart,
  removeAllFromCart,
} from "../features/products/cartSlice";
import { useDispatch, useSelector } from "react-redux";

import ProductImageCarasol from "../components/productCards/ProductImageCarasol";
import { toast } from "react-toastify";
import { singleProductFetching } from "../features/products/singleProductSlice";
import { thumbImageFetching } from "../features/products/thambImageSlice";

// components start
const ProductDetails = () => {
  const { items: singleProduct, status } = useSelector(
    (state) => state.singleProduct
  );

  const session = useSelector((state) => state.user.userAndToken);

  const { items: thumbImage } = useSelector((state) => state.thumbImage);
  const { items: sizes } = useSelector((state) => state.sizes);

  const dispatch = useDispatch();
  const { id } = useParams();

  // const [singleProduct, setSingleProduct] = useState({});
  // console.log(singleProduct);

  const [size, setSize] = useState([]);

  const [response, setResponse] = useState({});

  const [selectedOption, setSelectedOption] = useState(null);

  // const [imageThamb, setImageThamb] = useState([]);

  const [cartQuantity, setCartQuantity] = useState(1);
  const htmlParser = new Parser();

  const navigate = useNavigate();

  useEffect(() => {
    dispatch(singleProductFetching(id));
    dispatch(thumbImageFetching(id));
  }, [dispatch, id]);

  useEffect(() => {
    const SizeAttribute = async () => {
      const res = await axios.get(
        `${process.env.REACT_APP_URL}/api-specificattribute/${id}`
      );
      return setSize(res.data);
    };
    SizeAttribute();
  }, [id]);

  useEffect(() => {
    if (selectedOption) {
      fetch(`${process.env.REACT_APP_URL}/api-findproductstock`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          productid: singleProduct?.product?.id,
          variantid: selectedOption,
        }),
      })
        .then((response) => response.json())
        .then((data) => {
          // Handle the response data as needed
          console.log("Response from server:", data);
          setResponse(data);
        })
        .catch((error) => {
          console.error("Error:", error);
        });
    }
  }, [selectedOption, singleProduct?.product?.id]);

  const handleRadioClick = (opstion) => {
    setSelectedOption(opstion);
    setCartQuantity(1);
  };

  if (response.discount_price > 0) {
  }
  const handleDecrease = useCallback(() => {
    setCartQuantity(cartQuantity === 1 ? 1 : (prev) => prev - 1);
    cartQuantity > 1 &&
      toast.warn("Quantity Decreased", {
        position: "bottom-left",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
  }, [cartQuantity]);

  const handleIncrease = useCallback(() => {
    setCartQuantity((prev) => prev + 1);
    toast.warn("Quantity Increased", {
      position: "bottom-left",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "light",
    });
  }, []);
  const handleAddToCart = () => {
    if (!selectedOption) {
      toast.warn("Select your product size plse", {
        position: "bottom-left",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
    } else {
      // dispatch(removeAllFromCart());
      if (response.discount_price > 0) {
        dispatch(
          addToSingleCart({
            ...singleProduct.product,
            cartQuantity,
            sizeAtr: selectedOption,
            response: response?.discount_price,
          })
        );
      }
      if (response.price > 0 && response.discount_price < 1) {
        dispatch(
          addToSingleCart({
            ...singleProduct.product,
            cartQuantity,
            sizeAtr: selectedOption,
            response: response?.price,
          })
        );
      }

      // navigation("/checkout");
      setCartQuantity(1);
    }
  };
  const handleBuyNow = () => {
    if (!selectedOption) {
      toast.warn("seclect your product size plse", {
        position: "bottom-left",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
    } else {
      // dispatch(removeAllFromCart());
      if (response.discount_price > 0) {
        dispatch(
          addToSingleCart({
            ...singleProduct.product,
            cartQuantity,
            sizeAtr: selectedOption,
            response: response?.discount_price,
          })
        );
      }
      if (response.price > 0 && response.discount_price < 1) {
        dispatch(
          addToSingleCart({
            ...singleProduct.product,
            cartQuantity,
            sizeAtr: selectedOption,
            response: response?.price,
          })
        );
      }
      navigate("/checkout");
    }
  };

  return (
    <div className="container mx-auto bg-white min-h-screen p-2">
      <div className="grid  lg:grid-cols-[30%_40%_30%] gap-3 mt-5">
        <div className="left image ">
          <div className="w-full h-full overflow-hidden">
            <ProductImageCarasol
              singleProduct={`${process.env.REACT_APP_URL}/uploads/product/${singleProduct?.product?.photos}`}
              thumbImage={thumbImage}
              className="w-full h-full"
            />
          </div>
        </div>
        <div className="right flex flex-col gap-5 lg:gap-5 2xl:gap-8  p-3">
          <div className="font-semibold mt-5 text-lg lg:text-3xl">
            {singleProduct?.product?.name}
          </div>
          <div className="flex items-center justify-start gap-4 ">
            <span className="inline-block text-slate-600/50 text-[1rem] font-medium  uppercase items-center">
              size
            </span>
            <div className="grid grid-cols-3 md:grid-cols-4 xl:grid-cols-4 2xl:grid-cols-5 gap-3">
              {size?.map((s, index) => (
                <button
                  key={index}
                  onClick={() => handleRadioClick(s.variant)}
                  className={`border border-red-500 py-2 px-2 ${
                    selectedOption === s.variant ? "bg-red-600 text-white" : ""
                  }`}
                >
                  {s.variant}
                </button>
              ))}
            </div>
          </div>

          <div className="font-medium flex items-center gap-4 text-3xl">
            <span className="inline-block text-slate-600/50 text-[1rem] font-medium  uppercase items-center">
              price
            </span>
            {response.price > 0 ? (
              <>
                <div className="flex gap-5">
                  {response.discount_price > 0 ? (
                    <div className="flex gap-5">
                      <span className="font-light line-through text-black decoration-200">
                        ৳ {+response?.price}
                      </span>
                      <span className="text-red-600 font-light">
                        {+response.discount_price * cartQuantity}
                      </span>
                    </div>
                  ) : (
                    <div>
                      <div className="text-red-600 font-light">
                        {+response?.price * cartQuantity}
                      </div>
                    </div>
                  )}
                </div>
              </>
            ) : (
              <div className="text-red-600 font-light text-xl">
                <span>select your product size</span>
              </div>
            )}
          </div>

          <div className="flex items-center gap-3">
            <span className="inline-block text-slate-600/50 text-[1rem] font-medium  uppercase items-center">
              Quantity
            </span>
            <div className="flex items-center border border-gray-300 gap-4 ">
              <button
                className="h-10 w-10 bg-gray-100  border border-gray-300 active:bg-gray-700 active:text-gray-50 flex items-center justify-center"
                onClick={() => handleDecrease(singleProduct?.product)}
              >
                <AiOutlineMinus />
              </button>
              <span>{cartQuantity}</span>
              <button
                className="h-10 w-10 bg-gray-100  border border-gray-300 active:bg-gray-700 active:text-gray-50 flex items-center justify-center"
                onClick={() => handleIncrease(singleProduct?.product)}
              >
                <BsPlus />
              </button>
            </div>
          </div>

          <div className="grid grid-cols-2 gap-2 text-white">
            <div className="flex gap-3 w-full col-span-2">
              <button
                onClick={handleBuyNow}
                disabled={!response.price}
                className={`${
                  !selectedOption || !response.price
                    ? "bg-[#25A6CE] cursor-not-allowed"
                    : "bg-[#25A6CE] hover:bg-[#3A3A3A]"
                }  py-3 px-5 w-full rounded-md col-span-2  text-white duration-300`}
              >
                {/* "w-full py-3 px-5 bg-[#C3161C] hover:bg-gray-700 flex items-center
              justify-center" */}
                <span className="text-base md:text-2xl">অর্ডার করুন</span>
              </button>
              <button
                onClick={handleAddToCart}
                disabled={!response.price}
                className={`${
                  !selectedOption || !response.price
                    ? "bg-[#C55C1C] cursor-not-allowed"
                    : "bg-[#C55C1C] hover:bg-[#3A3A3A]"
                }  py-3 px-5 w-full rounded-md col-span-2  text-white duration-300`}
              >
                {/* "w-full py-3 px-5 bg-[#C3161C] hover:bg-gray-700 flex items-center
              justify-center" */}
                <span className="text-base md:text-2xl">Add To Cart</span>
              </button>
            </div>
            {/* <button
              onClick={handleAddToCart}
              className="w-full bg-[#FA5303] py-3 px-5 flex items-center justify-center"
            >
              Add to Cart
            </button> */}

            {/* <Link
              className="w-full py-2 px-5 bg-blue-500 hover:bg-[#ED423E] duration-300 col-span-2 flex flex-col items-center justify-center"
              to={`https://wa.me/+8801878206101`}
              target="_blank"
              rel="noreferrer"
            >
              <span>অর্ডার অথবা কল করতে ক্লিক করুন </span>
              <span>(+8801878206101)</span>
            </Link>

            <Link
              to={`https://m.me/sombhaar`}
              target="_blank"
              rel="noreferrer"
              className="w-full py-2 px-5 bg-blue-500 hover:bg-[#ED423E] duration-300 col-span-2 flex flex-col items-center justify-center"
            >
              <span>বিস্তারিত জানতে মেসেজ করুন</span>
              <span className="text-xl">
                <BsMessenger />
              </span>
            </Link> */}
          </div>
        </div>
        <div className="">
          <div></div>
        </div>
      </div>
      <div className="details p-3 mt-5">
        <div className="flex items-center justify-center">
          {/* <span>product details of{singleProduct?.product?.name}</span> */}
          <span className="text-black font-bold text-4xl mb-5">
            Product Descriptions
          </span>
        </div>

        <div className="description w-[100%]  overflow-x-scroll">
          {singleProduct?.product?.description ? (
            <span className="">
              {htmlParser.parse(singleProduct?.product?.description)}
            </span>
          ) : (
            <div className="text-2xl text-red-500 flex items-center justify-center">
              <span>No Description</span>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default ProductDetails;
