import React from "react";
import { Link } from "react-router-dom";
import Card from "../productCards/Card";

const CombineData = ({ combianData }) => (
  <>
    {combianData?.slice(0, 3).map((category) => (
      <section
        key={category?.id}
        className="product-section container mx-auto px-3"
      >
        <div className="header-section border-b-[0.5px] border-black mt-10 mb-5  flex justify-between items-center">
          <h2 className="title text-3xl">{category?.name}</h2>

          <Link
            to={`/categorypage/${category?.id}`}
            className="font-semibold text-xl"
          >
            View All
          </Link>
        </div>
        <div className="products-container gap-5 flex flex-wrap justify-center  ">
          {category?.products?.slice(0, 8).map((product) => (
            <Card key={product?.id} product={product} />
          ))}
        </div>
      </section>
    ))}
  </>
);

export default CombineData;
